import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

class Posts extends Component {
  render() {
    const { posts, parallax } = this.props
    return (
      <>
        {posts.map(({ node }) => (
          <div className="col s12 m6 l3">
            <div className="card recent-posts">
              <div className="card-image">
                {node.featured_media.localFile ? (
                  <Img
                    fluid={node.featured_media.localFile.childImageSharp.fluid}
                    alt={node.title}
                  />
                ) : (
                    <Img
                      fluid={parallax.childImageSharp.fluid}
                      alt={node.title}
                    />
                  )}
                <span className="card-title truncate">{node.title}</span>
              </div>
              <div className="card-content">
                <p
                  className="truncate"
                  dangerouslySetInnerHTML={{ __html: node.excerpt }}
                />
              </div>
              <div className="card-action">
                <Link className="purple-text" to={node.link}>
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default Posts
