import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PageHeader from '../utils/PageHeader'
import Layout from '../components/layout'
import Posts from '../utils/Posts'
import SEO from '../components/seo'

const SuccessPage = () => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "pageheader.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allWordpressPost(sort: { fields: date, order: DESC }, limit: 4) {
        edges {
          node {
            date(formatString: "MMM DD, YYYY")
            title
            excerpt
            slug
            featured_media {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      parallax: file(relativePath: { eq: "1hero.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Success" />
      <PageHeader img={data.img.childImageSharp.fluid}>
        <div className="container">
          <h1 className="portfolio-heading">Success</h1>
        </div>
      </PageHeader>
      <section style={{ marginTop: '50px' }}>
        <div className="container">
          <div className="row center">
            <h4>
              Thank you! Your form has been submitted. I shall get back to you
              very soon.
            </h4>
          </div>
        </div>
      </section>
      {/*Blog*/}
      <section className="blog container" style={{ marginTop: '30px' }}>
        <h5 className="center-align purple-text text-darken-3">
          While you wait, read some recent posts?
        </h5>
        <div className="row center">
          <Posts posts={data.allWordpressPost.edges} parallax={data.parallax} />
        </div>
      </section>
    </Layout>
  )
}

export default SuccessPage
